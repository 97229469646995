import React from 'react';
import SubmitHandler from './HandleSubmit';  // Ensure the path is correct based on your project structure

const PopupPanel = ({
  isPanelVisible, 
  ID, 
  PhoneNumber, 
  setID, 
  setPhoneNumber, 
  togglePanel, 
  setViewUrl, 
  setEditUrl
}) => {
  return (
    <>
      {isPanelVisible && (
        <div className="popup-panel">
          <h2>Input Panel</h2>
          <SubmitHandler 
            ID={ID} 
            PhoneNumber={PhoneNumber} 
            setID={setID} 
            setPhoneNumber={setPhoneNumber} 
            togglePanel={togglePanel} 
            setViewUrl={setViewUrl} 
            setEditUrl={setEditUrl} 
          />
        </div>
      )}
    </>
  );
};

export default PopupPanel;