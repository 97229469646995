import React,{useState}  from 'react';
import ButtonAndUrls from './ButtonAndUrls';  // Adjust the path as needed
import PopupPanel from './PopupPanel'; 

const AdminDashboard = () => {
    const [ID, setID] = useState('');
    const [PhoneNumber, setPhoneNumber] = useState('');
    const [viewUrl, setViewUrl] = useState('');
    const [editUrl, setEditUrl] = useState('');
    const [isPanelVisible, setIsPanelVisible] = useState(false);

    const togglePanel = () => {
        setIsPanelVisible(!isPanelVisible);
    };
    return (
      <div>
        <ButtonAndUrls 
        togglePanel={togglePanel} 
        viewUrl={viewUrl} 
        editUrl={editUrl} 
        />

        <PopupPanel 
        isPanelVisible={isPanelVisible}
        ID={ID}
        PhoneNumber={PhoneNumber}
        setID={setID}
        setPhoneNumber={setPhoneNumber}
        togglePanel={togglePanel}
        setViewUrl={setViewUrl}
        setEditUrl={setEditUrl}
      />

      </div>
    );
};
export default AdminDashboard;