import React from 'react';

const ButtonAndUrls = ({ togglePanel, viewUrl, editUrl }) => {
  return (
    <div>
      <button className="top-left-button" onClick={togglePanel}>Click Me</button>

      <div className="upper-right-container">
        <a href={viewUrl}>viewUrl</a>
        <a href={editUrl}>editUrl</a>
      </div>
    </div>
  );
};

export default ButtonAndUrls;