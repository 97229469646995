import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaPhone, FaTelegram, FaInstagram } from 'react-icons/fa';

const UrlHandler = () => {
  const { url } = useParams(); // Get the URL from the route parameters
  console.log(url);
  const [PhoneNumber, setPhoneNumber] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkUrl = async () => {
      try {
        const response = await fetch(`/api/${url}`);
        if (response.ok) {
          const data = await response.json();
          setPhoneNumber(data.PhoneNumber);
          console.log("111");
        } else if (response.status === 404) {
          setError("Page not found");
        } else {
          setError('An error occurred');

        }
      } catch (err) {
        setError('An error occurred');
      }
    };

    checkUrl();
  }, [url]);

  if (error) {
    return <div>{error}</div>;
  }

  if (PhoneNumber) {
    return (
      <div style={{ backgroundColor: '#1D1D1D', height: '100vh' }}>
        <header style={{ backgroundColor: 'white', padding: '20px', textAlign: 'center' }}>
          <h1 style={{ color: '#1D1D1D' }}>Centered Header Text</h1>
        </header>
        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
          <a href={"tel:"+PhoneNumber} style={{ textDecoration: 'none' }}>
            <FaPhone style={{ fontSize: '36px', border: '2px solid #3AFF3A', borderRadius: '50%', padding: '10px', marginRight: '20px', color: '#3AFF3A' }} />
          </a>
          <a href="tg:resolve?domain=@rezamoeini" style={{ textDecoration: 'none' }}>
          <FaTelegram style={{ fontSize: '36px', border: '2px solid #3AFF3A', borderRadius: '50%', padding: '10px', marginRight: '20px', color: '#3AFF3A' }} />
          </a>
          <a href="instagram://user?username=_im_the_me_" style={{ textDecoration: 'none' }}>
          <FaInstagram style={{ fontSize: '36px', border: '2px solid #3AFF3A', borderRadius: '50%', padding: '10px', color: '#3AFF3A' }} />
          </a>
        </div>
      </div>
    );
  }

  return <div>Loading...</div>;
};

export default UrlHandler;